import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Models from '@solid-ui-blocks/Modeller/Models'
import httpService from '../../service/httpService'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const KutuTasarimiModelSec = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const [modelListesi, setList] = useState([]);

  const [dtID, setIDBilgi] = useState("0");

  useEffect(() => {
    // if (props.location.state != null) {
    //   localStorage.setItem("pM", props.location.state.dt);
    // }

    var ddd = localStorage.getItem("pM");
    setIDBilgi(ddd);
    cokBenzerLis(ddd);
  }, [dtID]);

    
  function cokBenzerLis(mdlId) {
  
    httpService.post("UnLocked/CokBenzerModelList", { params: mdlId }).then((res) => {
      if (res.DogruMu) {
        setList(res)
      } else {
    
      }
    }).catch((err) => {
     
    });
 
}

  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Modeller - Oluklu Mukavva Promosyon Ambalaj Modelleri ' />
      <Divider space='5' />
      <Models content={modelListesi} pageR={{ pageR: "benzer" }} ckid={ dtID }  />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageKutuTasarimiModelSecBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default KutuTasarimiModelSec
